
import axios from 'axios';
export default {
    
    async createIligalHours(params)  {
        return await axios.post(`iligal_hours/create` , params)
    },
    async createIligalHoursList(params)  {
        return await axios.post(`iligal_hours/create/list` , params)
    },
    async updateIligalHoursColumn(column , value , data)  {
        return await axios.put(`iligal_hours/update_list?${column}=${value}` , data)
    },
    async deleteIligalHoursList(list)  {
        return await axios.delete(`iligal_hours/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportIligalHours(column , value)  {
        return await axios.get(`iligal_hours/report?${column}=${value}`)
    },
    async getAllIligalHours()  {
        return await axios.get(`iligal_hours/all`)
    },
    async getOneIligalHours(iligal_hour_id)  {
        return await axios.get(`iligal_hours/all/${iligal_hour_id}`)
    },
    async getIligalHoursByColumn(column , value)  {
        return await axios.get(`iligal_hours/filter?column=${column}&value=${value}`)
    },
    async deleteIligalHours(iligal_hour_id)  {
        return await axios.delete(`iligal_hours/delete/${iligal_hour_id}`)
    },
    async updateIligalHours(iligal_hour_id , params)  {
        return await axios.put(`iligal_hours/update/${iligal_hour_id}` , params)
    }
}